import { Locale } from '../../Entities/Locale/Locale.entity';
import { fetchJson } from '../Fetch/FetchJson';
import { clientLog } from '../Log/ClientLog';
import { getReCaptchaToken } from '../loadGoogleRecaptcha';

// Define a type for the items
interface VehicleItem {
  vehicle: {
    primary_id: string;
  };
}

// Define a type for the customer's address
interface Address {
  postcode: string;
}

// Define a type for optin_preferences
interface OptinPreferences {
  optin_source: null | string;
}

// Define a type for the customer
interface Customer {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: Address;
  optin_preferences: OptinPreferences;
}

// Define a type for arbitrary_data
interface ArbitraryData {
  message: string;
  referring_page: string;
}

// Finally, define the type for the main object
interface LeadBody {
  lead_type: string;
  subtype: string;
  status: string;
  source: string;
  is_test: boolean;
  final: boolean;
  attribution: null | string;
  items: VehicleItem[];
  customer: Customer;
  arbitrary_data: ArbitraryData;
}

export const postLead = async (
  locale: Locale,
  body: LeadBody,
  utmParams?: { utmSource?: string; utmMedium?: string; utmCampaign?: string },
) => {
  //TODO: track lead event https://docs.google.com/spreadsheets/d/1LGLXREVUAL_3HqwnbVbRBTkDSFZ0WnxXcNA_UnZM1vY/edit?pli=1#gid=1437344502
  //TODO: resolve user from id
  const url =
    utmParams?.utmCampaign && utmParams?.utmMedium && utmParams?.utmSource
      ? `/api/leads/leads?utm_source=${utmParams?.utmSource}&utm_medium=${utmParams?.utmMedium}&utm_campaign=${utmParams?.utmCampaign}`
      : '/api/leads/leads';
  const leadType = body?.subtype;

  clientLog({ label: `${leadType} lead submit`, data: { body } });

  let response: any;
  let ipAddress = '';
  const reCaptchaToken = await getReCaptchaToken('lead', locale);
  try {
    ipAddress = await fetchJson('/api/get-ip');
  } catch (error) {
    console.error('Error retrieving Client IP address', error);
  }
  try {
    response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-heycar-tenant': locale,
        'Recaptcha-Token': reCaptchaToken,
        'x-original-forwarded-for': ipAddress,
      },
      body: JSON.stringify(body),
    });
  } catch (error) {
    console.error(`[Error submitting ${leadType} lead]`, error);
    return false;
  }
  if (response.status === 500) {
    console.error('Internal server error. Check the server logs.', response);
    return false;
  }
  const data = await response.json();
  const leadResponse = data?.lead;

  if (!leadResponse?.lead_id) {
    console.error(`[Error submitting ${leadType} lead]`, body);
    return false;
  }

  return leadResponse;
};
