'use client';

import { atomWithStorage } from 'jotai/utils';

import { PreApprovalConfig } from '../../Entities/Config/PreApprovalConfig.entity';

export const preApprovalConfigAtom = atomWithStorage<PreApprovalConfig | null>(
  'preApprovalConfig',
  null,
);
