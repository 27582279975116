import { Locale } from '@core/Entities/Locale/Locale.entity';
import { googleRecaptchaSiteKeyFR, googleRecaptchaSiteKeyUK } from '@core/Environment/Variables';

const googleRecaptchaKeys: Record<Locale, string> = {
  fr: googleRecaptchaSiteKeyFR,
  uk: googleRecaptchaSiteKeyUK,
};

export const loadGoogleRecaptcha = (locale: Locale) => {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.google.com/recaptcha/api.js?render=${googleRecaptchaKeys[locale]}`;

      script.onload = function () {
        resolve('Google ReCaptcha loaded successfully');
      };

      script.onerror = function () {
        reject('Error: Unable to load Google ReCaptcha script');
      };
      head.appendChild(script);
    } else {
      resolve('success');
    }
  });
};

export const getReCaptchaToken = (action: string, locale: Locale = 'uk') => {
  return new Promise(res => {
    const { grecaptcha }: any = window;
    if (grecaptcha) {
      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute(googleRecaptchaKeys[locale], { action });
          return res(token);
        } catch (err) {
          return res('');
        }
      });
    } else {
      return res('');
    }
  });
};
