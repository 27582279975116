import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

import { LeadEvent } from './LeadEvent';
import { leadSchema } from './LeadSchema';

type Props = {
  vehicle: Vehicle | null;
  values: LeadFormState;
  leadType: string;
  leadId?: string;
  source?: string;
};

export const leadToEvent = ({
  vehicle,
  values,
  leadType,
  leadId,
  source = 'PDP',
}: Props): LeadEvent => {
  const event: LeadEvent = {
    schema: leadSchema.ref,
    data: {
      lead_id: leadId,
      lead_type: leadType,
      location: source,
      trade_in: values.registration,
      price: vehicle?.pricing.price,
      monthly_price: vehicle?.pricing.monthlyPrice ?? undefined,
      requested_datetime: values.date,
    },
  };

  return event;
};
